<template>
  <div class="deal-with-facilities">
    <x-table
      :no-data-text="
        CA('dealFacilities_check') ? '暂无数据' : '暂无数据查询权限'
      "
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @loadEnter="loadEnter"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="480"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <FormItem label="设施名称" prop="name">
          <Input v-model="form.name" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="设施类型" prop="category">
          <Select v-model="form.category" style="width: 300px">
            <Option
              v-for="item in categoryList"
              :value="item.id + '/' + item.name"
              :key="item.id"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="所属养殖区" prop="breed">
          <Cascader
            :data="farmScene"
            v-model="form.breed"
            @on-change="breedChange"
            style="width: 300px"
          ></Cascader>
        </FormItem>
        <FormItem label="规模" prop="type">
          <!-- <Input v-model="form.size"></Input> -->
          <RadioGroup v-model="form.type">
            <Radio label="1">
              <span>长度</span>
            </Radio>
            <Radio label="2">
              <span>容量</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="长度" prop="length" v-if="form.type == '1'">
          <Input v-model="form.length" style="width: 300px">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem label="直径" prop="diameter" v-if="form.type == '1'">
          <Input v-model="form.diameter" style="width: 300px">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem label="长" prop="long" v-if="form.type == '2'">
          <Input v-model="form.long" style="width: 300px">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem label="宽" prop="width" v-if="form.type == '2'">
          <Input v-model="form.width" style="width: 300px">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem label="高" prop="high" v-if="form.type == '2'">
          <Input v-model="form.high" style="width: 300px">
            <span slot="append">m</span>
          </Input>
        </FormItem>
        <FormItem label="单位" prop="unit">
          <Input v-model="form.unit" style="width: 300px"></Input>
        </FormItem>
        <FormItem
          v-if="
            form.category && form.category.split('/')[1].indexOf('管道') == -1
          "
          label="设施位置"
          prop="thirdLongLat"
        >
          <Input
            v-model="form.thirdLongLat"
            disabled
            style="width: 300px"
            :placeholder="
              form.breed.length > 0
                ? '点击右侧图标选择设施位置'
                : '请先选择养殖区'
            "
          >
            <span
              slot="append"
              class="iconfont"
              @click="mapModalShow"
              :style="{
                cursor: form.breed.length > 0 ? 'pointer' : 'not-allowed',
              }"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem
          v-if="
            form.category && form.category.split('/')[1].indexOf('管道') > -1
          "
          label="管道绘制"
          prop="mapPosition"
        >
          <Input
            style="width: 300px"
            v-model="form.mapPosition"
            disabled
            :placeholder="
              form.breed.length > 0 ? '点击右侧图标绘制管道' : '请先选择养殖区'
            "
          >
            <span
              slot="append"
              class="iconfont"
              @click="drawMapModalShow"
              :style="{
                cursor: form.breed.length > 0 ? 'pointer' : 'not-allowed',
              }"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="设施状态" prop="status">
          <Select v-model="form.status" style="width: 300px">
            <Option :value="0">闲置</Option>
            <Option :value="1">工作</Option>
            <Option :value="2">废弃</Option>
          </Select>
        </FormItem>
        <FormItem label="设施图纸" prop="drawings">
          <upload-image
            v-model="form.drawings"
            :max="5"
            :clearFlag="!modal.show"
          ></upload-image>
        </FormItem>
        <FormItem label="设施图片" prop="img">
          <upload-image
            v-model="form.img"
            :max="5"
            :clearFlag="!modal.show"
          ></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="mapModel" title="选择设施位置" fullscreen>
      <search-map
        v-if="mapModel"
        :backgroundPosition="backgroundPosition"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModel = false)">提交</Button>
        <Button @click="() => (mapModel = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="drawMapModal" title="绘制管道" fullscreen>
      <draw-map
        v-if="drawMapModal"
        :backgroundPosition="backgroundPosition"
        ref="map"
        :mapPosition="mapPosition2"
      ></draw-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="drawFinish">提交</Button>
        <Button @click="() => (drawMapModal = false)">取消</Button>
      </p>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import CU from "@/common/util";
import searchMap from "@/components/searchMap";
import drawMap from "@/components/drawMap";
import pictureView from "@/components/picture_view";
export default {
  name: "",
  components: {
    searchMap,
    drawMap,
    pictureView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "设施名称",
            minWidth: 100,
            key: "name",
          },
          {
            title: "设施类型",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "所属基地",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                // ${ row.sceneId ? "/" + row.sceneName : ""}
                <span>{`${row.farmName}

                  `}</span>
              );
            },
          },
          {
            title: "所属养殖区",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.sceneName}</span>;
            },
          },
          {
            title: "规模(长度，容量)",
            minWidth: 100,
            render: (h, { row }) => {
              let size = JSON.parse(row.size);
              return (
                <span>
                  {size.type == "1"
                    ? "长度：" +
                      size.value.length +
                      "m，直径：" +
                      size.value.diameter +
                      "m"
                    : `${size.value.long}×${size.value.width}×${size.value.high}m³`}
                </span>
              );
            },
          },
          {
            title: "设施图片",
            width: 135,
            render: (h, { row }) => {
              if (row.img) {
                return (
                  <div style="display:flex">
                    {row.img.split(",").map((item) => {
                      return (
                        <img
                          src={this.getImgUrl(row.servicePath, item)}
                          style="width: 35px;height: 35px;margin-right:10px;cursor:pointer"
                          onClick={() =>
                            this.lookPicture(row.servicePath + item)
                          }
                        />
                      );
                    })}
                  </div>
                );
              } else {
                return <span>--</span>;
              }
            },
          },
          {
            title: "图纸",
            width: 135,
            render: (h, { row }) => {
              if (row.drawings) {
                return (
                  <div style="display:flex">
                    {row.drawings.split(",").map((item) => {
                      return (
                        <img
                          src={this.getImgUrl(row.servicePath, item)}
                          style="width: 35px;height: 35px;cursor:pointer;margin-right:10px"
                          onClick={() =>
                            this.lookPicture(row.servicePath + item)
                          }
                        />
                      );
                    })}
                  </div>
                );
              } else {
                return <span>--</span>;
              }
            },
          },
          {
            title: "设施状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <span>
                  {row.status == 0 ? "闲置" : row.status == 1 ? "工作" : "废弃"}
                </span>
              );
            },
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("dealFacilities_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("dealFacilities_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        category: "",
        categoryId: "",
        categoryName: "",
        breed: [],
        farmId: "",
        sceneId: "",
        size: "",
        unit: "",
        thirdLongLat: "",
        mapPosition: "",
        drawings: "",
        img: "",
        siteType: "2",
        type: "1",
        length: "",
        long: "",
        width: "",
        high: "",
        //直径
        diameter: "",
        status: "",
        name: "",
      },
      rules: {
        category: [{ required: true, message: "请选择设施类型" }],
        name: [{ required: true, message: "请填写设施名称" }],
        breed: [{ required: true, type: "array", message: "请选择所属养殖区" }],
        size: [
          { required: true, message: "请填写规模" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的规模信息",
          },
        ],
        unit: [{ required: true, message: "请填写单位" }],
        thirdLongLat: [{ required: false, message: "请选择设施位置" }],
        mapPosition: [{ required: false, message: "请绘制管道" }],
        length: [
          { required: true, message: "请填写长度" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "长度只能是数字",
          },
        ],
        diameter: [
          { required: true, message: "请填写直径" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "直径只能是数字",
          },
        ],
        long: [
          { required: true, message: "请填写长" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "长只能是数字",
          },
        ],
        width: [
          { required: true, message: "请填写宽" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "宽只能是数字",
          },
        ],
        high: [
          { required: true, message: "请填写高" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "高只能是数字",
          },
        ],
        status: [{ required: true, message: "请选择设施状态" }],
      },
      farmScene: [],
      categoryList: [],

      mapModel: false,
      currentData: null,

      drawMapModal: false,
      mapPosition2: null,

      picture: {
        src: "",
        show: false,
      },

      backgroundPosition: [],
      farmList: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增设施",
            ca: "dealFacilities_add",
          },
          width: 220,
          loadModel: {
            url: "https://castoff.hogdata.cn/app/设施.xlsx",
          },
          loadEnter: {
            loadName: "导入",
            ca: "dealFacilities_import",
          },
          filterBox: [
            {
              conditionName: "设施名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "设施类型",
              component: "select",
              field: "categoryId",
              defaultValue: "",
              data: this.categoryList,
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "所属基地",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farmList,
              parameterField: "id",
              showField: "name",
              clearable: true,
            },
            {
              conditionName: "所属养殖区",
              component: "cascader",
              field: "sceneId",
              defaultValue: [],
              data: this.farmScene,
              isLoadData: true,
              loadData: this.loadData,
            },
            {
              conditionName: "设施状态",
              component: "select",
              field: "status",
              defaultValue: "",
              data: [
                { name: "闲置", value: "0" },
                { name: "工作", value: "1" },
                { name: "废弃", value: "2" },
              ],
              parameterField: "value",
              showField: "name",
              clearable: true,
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        formdata.append("siteType", 2);
        this.$post(this.$api.DEAL_FACILITIES.UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.getList();
        });
      };
      input.click();
    },
    mapModalShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModel = true;
    },
    dealMapData(positionData) {
      this.form.thirdLongLat = positionData.thirdLongLat;
    },
    drawMapModalShow() {
      if (this.form.breed.length == 0) return;
      this.mapPosition2 = this.form.mapPosition;
      this.drawMapModal = true;
    },
    drawFinish() {
      let n = this.$refs.map.getCurrentFeatures();
      this.form.mapPosition = JSON.stringify(n);
      this.drawMapModal = false;
    },
    add() {
      this.modal = {
        show: true,
        title: "新增设施",
        submitLoading: false,
      };
    },

    search(value) {
      this.page.pageNo = 1;
      // this.search_data = data;
      let n;
      if (value.sceneId.length >= 2) {
        for (let i = 0; i < value.sceneId.length; i++) {
          n = value.sceneId[value.sceneId.length - 1];
        }
      } else {
        n = "";
      }
      let obj = {
        ...value,
        sceneId: n,
      };
      this.search_data = obj;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },

    getList() {
      if (!this.CA("dealFacilities_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DEAL_FACILITIES.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getBreedArea() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "2",
      }).then((res) => {
        this.farmSceneList = res;
        this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList", [
          "mapPosition",
        ]);
        console.log(this.farmScene);
      });
    },
    breedChange(value, selectedData) {
      let backgroundPosition = [];
      for (let i = 0; i < selectedData.length; i++) {
        let item = selectedData[i];
        item.mapPosition &&
          backgroundPosition.push(...JSON.parse(item.mapPosition));
      }
      this.backgroundPosition = backgroundPosition;
      this.form.farmId = value[0];
      this.form.sceneId = value[1];
    },
    getCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "24",
      }).then((res) => {
        this.categoryList = res.list;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      let backgroundPosition = [];
      let farm = this.farmScene.find((item) => item.value == row.farmId);
      if (farm && farm.mapPosition) {
        backgroundPosition.push(...JSON.parse(farm.mapPosition));
        let scene = farm.children.find((it) => it.value == row.sceneId);
        scene && backgroundPosition.push(...JSON.parse(scene.mapPosition));
      }
      this.backgroundPosition = backgroundPosition;
      this.form.category = row.categoryId + "/" + row.categoryName;
      this.form.breed = [row.farmId, row.sceneId];
      row.drawings &&
        (this.form.drawings = row.drawings
          .split(",")
          .map((item) => this.getImgUrl(row.servicePath, item))
          .join(","));
      row.img &&
        (this.form.img = row.img
          .split(",")
          .map((item) => this.getImgUrl(row.servicePath, item))
          .join(","));
      if (row.size.indexOf("{") !== -1) {
        let size = JSON.parse(row.size);
        this.form.type = size.type;
        if (size.type == 1) {
          this.form.length = size.value.length;
          this.form.diameter = size.value.diameter;
        } else {
          this.form.long = size.value.long;
          this.form.width = size.value.width;
          this.form.high = size.value.high;
        }
      }
      // this.getMapPosition(this.form.breed);
      this.modal = {
        show: true,
        title: "编辑设施",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.DEAL_FACILITIES.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        category: "",
        categoryId: "",
        categoryName: "",
        breed: [],
        farmId: "",
        sceneId: "",
        size: "",
        unit: "",
        thirdLongLat: "",
        mapPosition: "",
        drawings: "",
        img: "",
        siteType: "2",
        type: "1",
        length: "",
        long: "",
        width: "",
        high: "",
        status: "",
        diameter: "",
        name: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.categoryId = this.form.category.split("/")[0];
        params.categoryName = this.form.category.split("/")[1];
        this.form.drawings &&
          (params.drawings = this.form.drawings
            .split(",")
            .map((item) => "image" + item.split("image")[1])
            .join(","));
        this.form.img &&
          (params.img = this.form.img
            .split(",")
            .map((item) => "image" + item.split("image")[1])
            .join(","));
        let obj1 = {
          length: this.form.length,
          diameter: this.form.diameter,
        };
        let obj2 = {
          long: this.form.long,
          width: this.form.width,
          high: this.form.high,
        };
        params.size = JSON.stringify({
          type: this.form.type,
          value: this.form.type == "1" ? { ...obj1 } : { ...obj2 },
        });
        params.type = "";
        params.length = "";
        params.diameter = "";
        params.long = "";
        params.width = "";
        params.high = "";
        delete params.category;
        delete params.breed;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.DEAL_FACILITIES.EDIT
            : this.$api.DEAL_FACILITIES.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },

    getFarmList() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 9999,
        siteType: "2",
      }).then((res) => {
        this.farmList = res.list;
      });
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
  mounted() {
    this.getList();
    this.getBreedArea();
    this.getCategory();
    this.getFarmList();
  },
};
</script>

<style lang="less" scoped>
.deal-with-facilities {
  width: 100%;
  height: 100%;
}
</style>
